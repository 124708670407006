import { EquipmentCodeIndMappingConstant, equipmentCodeIndMappingRequiredFieldList } from "presentation/constant/EquipmentCodeIndMapping/EquipmentCodeIndMappingConstant";
import { useEquipmentCodeIndMappingVM } from "presentation/hook/EquipmentCodeIndMapping/useEquipmentCodeIndMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useEquipmentCodeIndMappingTracked } from "presentation/store/EquipmentCodeIndMapping/EquipmentCodeIndMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const EquipmentCodeIndMappingEditPanel = () => {
    const EQUIPMENT_CODE_IND_MAPPING_CONSTANT = EquipmentCodeIndMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [equipmentCodeIndMappingState] = useEquipmentCodeIndMappingTracked();
    const equipmentCodeIndMappingVM = useEquipmentCodeIndMappingVM();
    const { currentEditRow, isAdd, isEdit } = equipmentCodeIndMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (currentEditRow.equipmentCodeList && currentEditRow.equipmentCodeList.length === 0) {
            messageBarVM.showError("Equipment Code is mandatory.");
            return;
        }
        if (!currentEditRow.equipmentCodeInd) {
            messageBarVM.showError("Equipment Code Ind is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await equipmentCodeIndMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            equipmentCodeIndMappingVM.searchAllEquipmentCodeIndMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [equipmentCodeIndMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        equipmentCodeIndMappingVM.onCloseEidtPanel();
    }, [equipmentCodeIndMappingVM]);


    const memoEquipmentCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.equipmentCodeList?.join('&')}
                fieldValue={currentEditRow?.equipmentCodeList}
                fieldLabel={EQUIPMENT_CODE_IND_MAPPING_CONSTANT.EQUIPMENT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'equipmentCodeList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={equipmentCodeIndMappingState.dynamicOptions.equipmentCodeDropdownOptions}
                requiredFieldList={equipmentCodeIndMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    equipmentCodeIndMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [EQUIPMENT_CODE_IND_MAPPING_CONSTANT.EQUIPMENT_CODE, currentEditRow?.equipmentCodeList, equipmentCodeIndMappingState.dynamicOptions.equipmentCodeDropdownOptions, equipmentCodeIndMappingVM, isAdd, isEdit])


    const memoEquipmentCodeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.equipmentCodeInd || ''}
                fieldValue={currentEditRow?.equipmentCodeInd}
                fieldLabel={EQUIPMENT_CODE_IND_MAPPING_CONSTANT.EQUIPMENT_CODE_IND}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'equipmentCodeInd'}
                maxLength={5}
                requiredFieldList={equipmentCodeIndMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    equipmentCodeIndMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [EQUIPMENT_CODE_IND_MAPPING_CONSTANT.EQUIPMENT_CODE_IND, currentEditRow?.equipmentCodeInd, equipmentCodeIndMappingVM, isAdd, isEdit])


    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={equipmentCodeIndMappingRequiredFieldList}
                fieldLabel={EQUIPMENT_CODE_IND_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => equipmentCodeIndMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [EQUIPMENT_CODE_IND_MAPPING_CONSTANT.ACTIVE_IND, currentEditRow?.activeInd, equipmentCodeIndMappingVM, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        equipmentCodeIndMappingVM.onEditClick();
    }, [equipmentCodeIndMappingVM]);

    const handleReset = useCallback(() => {
        equipmentCodeIndMappingVM.onResetClick(isAdd);
    }, [equipmentCodeIndMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: EquipmentCodeIndMappingConstant.Title.TITLE },
                    { title: EquipmentCodeIndMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}

                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Equipment Code Indicator Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.equipmentCodeList?.join('&'))}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <CriteriaItemContainer>
                            {memoEquipmentCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoEquipmentCodeInd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>


                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(EquipmentCodeIndMappingEditPanel);
