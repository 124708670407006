
export const EquipmentCodeIndMappingConstant = {
    Table: {
        PRIORITY: "Priority",
        EQUIPMENT_CODE: "Equipment Code",
        EQUIPMENT_CODE_IND: "Equipment Code Ind.",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        TITLE: "Equipment Code Indicator Setting",
    },
}
export const equipmentCodeIndMappingRequiredFieldList: string[] = [
    'equipmentCodeList',
    'equipmentCodeInd',
    'activeInd',
];